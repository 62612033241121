import React from 'react';
import TopBar from '../Components/topBar';
import Navbar from '../Components/navbar';
import Header from '../Components/header';
import Services from '../Components/services';
import About from '../Components/about';
import ImgGallery from '../Components/images';
import Contact from '../Components/contact';
import Footer from '../Components/footer';

import '../index.css'

const HomePage = () => { 

    return(
        
        <> 
           <TopBar/>
           <Navbar/>
           <Header/>
           <About/>
           <Services/>
           <ImgGallery/>
           <Contact/>
           <Footer/>
        </>
    )
};

export default HomePage;