import './App.css';
import HomePage from './pages/index';
import ImprintPage from './pages/imprint';
import DataProtectionPage from './pages/dataProtection';

import {
  BrowserRouter as Router, Routes, Route
} from 'react-router-dom';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} exact />
        <Route path='/impressum' element={<ImprintPage />} exact />
        <Route path='/dataProtection' element={<DataProtectionPage />} exact />
      </Routes>
    </Router>
  </>
  );
}

export default App;
