import React from 'react';

import Topbar from '../Components/topBar';
import Nav from '../Components/navbar';
// import Header from '../Components/header';
import Imprint from '../Components/imprint';
import Footer from '../Components/footer';

const ImprintPage = () => { 

    return(
        
        <> 
           <Topbar/>
           <Nav/>
           {/* <Header/> */}
           <Imprint/>
           <Footer/>
        </>
    )
};

export default ImprintPage;