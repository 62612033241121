import React from 'react';

import Topbar from '../Components/topBar';
import Nav from '../Components/navbar';
// import Header from '../Components/header';
import DataProtection from '../Components/dataProtection';
import Footer from '../Components/footer';

const DataProtectionPage = () => { 

    return(
        <> 
           <Topbar/>
           <Nav/>
           {/* <Header/> */}
           <DataProtection/>
           <Footer/>
        </>
    )
};

export default DataProtectionPage;