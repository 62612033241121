import React, {useEffect} from 'react';
import '../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../extensions/fontAwesome/css/all.min.css';
import '../extensions/fontAwesome/webfonts/fa-regular-400.ttf';

const ContactContent = () => {
  useEffect(() => {
    AOS.init();
  }, [])
    return(
        <section id="contact">
          <h1>Kontakt</h1>
          {/* <hr/> */}
          <div className='content'>
            <div className='contactContainer'>
              <h2>
                Wir freuen uns, von Ihnen zu hören!
                <br/> 
                Wenn Sie Fragen zu unseren Bau- und Gartenbaudienstleistungen haben oder ein Angebot anfordern möchten, stehen wir Ihnen jederzeit zur Verfügung. 
                <br/>
                Kontaktieren Sie uns einfach über die unten stehenden Kontaktdaten:
              </h2>
              <div className='contactInformation'>
                <div className='info'>
                  <h3><i className="fa-solid fa-user"/> Ansprechpartner:</h3>
                  <h4>Bali Zeka</h4>
                </div>
                <div className='info'>
                  <h3><i class="fa-solid fa-location-dot"/> Firmensitz:</h3>
                  <h4>Esslingen am Neckar</h4>
                </div>
                <div className='info'>
                  <h3><i className="fa-solid fa-envelope"/>E-mail:</h3>
                  <a href="mailto:info@bz-dienstleistungen.de ? Subject = Terminanfrage von [Name einfügen]">info@bz-dienstleistungen.de</a>
                </div>
                <div className='info'>
                  <h3><i className="fa-solid fa-mobile-screen-button"/> Mobil:</h3>
                  <a href="tel:+491726274407">+49 172 6274407</a>
                </div>
              </div>
            </div>
          </div> 
  </section>
    )
}

export default ContactContent;