import React from 'react';
import '../App.css';
import '../extensions/fontAwesome/css/all.min.css';
import '../extensions/fontAwesome/webfonts/fa-regular-400.ttf';

const ImprintContent = () => {
    return(
        <section id="legalNotice">
        <div className='legalNotice'>
          <h1>Impressum</h1>
          
          <p>Angaben gemäß § 5 TMG:</p>

          <p>BZ Dienstleistungen<br/>Inhaber: Bali Zeka<br/>Adresse: Robert-Koch-Straße 5<br/>PLZ/Ort: 73730 Esslingen am Neckar<br/>Telefon: +49 172 6274407<br/>E-Mail: info@bz-dienstleistungen.de<br/>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE 5909261661<br/>Registergericht: Esslingen</p>
          <p>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br/>Bali Zeka<br/>Robert-Koch-Straße 5<br/>73730 Esslingen am Neckar</p>
          <p>Haftungsausschluss:<br/>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
          <p>Bildnachweis:<br/>Die Bilder auf dieser Website stammen aus folgenden Quellen:<br/>- www.pexels.com</p>

          <p>
          Inhaltlich verantwortlich:<br/>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>

          <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>  
        </div>
    </section>
    )
}

export default ImprintContent;