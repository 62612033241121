import React from 'react';
import { Link } from 'react-router-dom';
import navLogo from '../images/LogoBZDiensleistungen.png';
import '../App.css';
import '../extensions/fontAwesome/css/all.min.css';
import '../extensions/fontAwesome/webfonts/fa-regular-400.ttf';

const Navbar = () => {
    return(
        <nav id='navbar'>
            <div className="navContainer">
                <Link to="/" className="navbarLogo" reloadDocument>
                <img className='navImg' src={navLogo} alt=''/>
                </Link>
                {/* <ul className="navbarMenu">
                <li className="navbarItem">
                    <Link to="/#contact" className="navbarLink">
                    Angebot
                    </Link>
                </li>
                <li className="navbarItem">
                    <Link to="/projektgalerie" className="navbarLink">
                    Projektgalerie
                    </Link>
                </li>
                <li className="navbarItem">
                    <Link to="/kontakt" className="navbarLink">
                    Kontakt
                    </Link>
                </li>
                </ul> */}
            </div>
        </nav>
    )
}

export default Navbar;