import React from 'react';
import '../App.css';
import '../extensions/fontAwesome/css/all.min.css';
import '../extensions/fontAwesome/webfonts/fa-regular-400.ttf';

const TopbarContent = () => {
    return(
        <section id="topbar">
            <div className="topbarItems">
              <div className="item">
                <a href="mailto:contact@example.com"><i className="fas fa-envelope"></i>info@bz-dienstleistungen.de</a>
              </div>
              <div className="item">
                <a href="tel:+49 172 6274407"><i className="fas fa-mobile-alt"></i>+49 172 6274407</a> 
              </div>
          </div>
        </section>
    )
}

export default TopbarContent;