import React, {useEffect} from 'react';
import '../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import BZ from '../images/LogoBZDiensleistungen.png'
import '../extensions/fontAwesome/css/all.min.css';
import '../extensions/fontAwesome/webfonts/fa-regular-400.ttf';

const AboutContent = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    return(
        <section id="about">
            <div className="aboutContainer">
                <div className='aboutTeam'>
                    <img src={BZ} alt=''/>
                    <h1>Bali Zeka</h1>
                    <h2>Inhaber des Unternehmens</h2>
                </div>
                <div className='aboutText'>
                    <p>Willkommen bei unserem Unternehmen, Ihrem zuverlässigen Partner für Hausdienstleistungen! Unser umfassendes Angebot an Dienstleistungen umfasst nicht nur die Instandhaltung Ihres Hauses, sondern auch alle Arbeiten rund ums Haus. Wir streben nach vollständiger Kundenzufriedenheit und liefern zuverlässige Arbeit zu einem guten Preis-Leistungs-Verhältnis.
                    Wir verstehen, dass jedes Haus einzigartig ist und verschiedene Anforderungen hat. Aus diesem Grund bieten wir eine breite Palette von maßgeschneiderten Dienstleistungen an, die auf Ihre spezifischen Bedürfnisse zugeschnitten sind. Wir sind bestrebt, unsere Kunden bei jedem Schritt des Weges zu unterstützen und ihnen die besten Ergebnisse zu liefern.
                    Unser Team besteht aus hochqualifizierten und erfahrenen Fachleuten, die über das Wissen und die Fähigkeiten verfügen, um Ihre Anforderungen zu erfüllen. Wir arbeiten hart daran, sicherzustellen, dass jedes Projekt pünktlich und mit höchster Qualität abgeschlossen wird.
                    Wenn Sie Fragen haben oder weitere Informationen benötigen, zögern Sie nicht, uns zu kontaktieren. Wir sind immer bereit, Ihnen zu helfen und Ihre Anforderungen zu erfüllen. Wir freuen uns darauf, mit Ihnen zusammenzuarbeiten und Ihre Erwartungen zu übertreffen.</p>
                </div>
            </div>
        </section>
    )
}

export default AboutContent;