import React from 'react';
import '../App.css';
import { Link } from "react-scroll";
// import logo from '../images/LogoBZDiensleistungen.png';
import '../extensions/fontAwesome/css/all.min.css';
import '../extensions/fontAwesome/webfonts/fa-regular-400.ttf';


const HeaderContent = () => {
    return(
        <header id="header">
            <div className="headerContainer">
                <h1>Professionelle Hilfe für Ihr Zuhause</h1>
                <h2>BZ - Dienstleistungen</h2>
                <p>Holen Sie sich die besten Experten für alle Arbeiten rund ums Haus. Wir bieten eine breite Palette von Dienstleistungen an, von Renovierungen über Reparaturen bis hin zur Gartenarbeit. Unsere erfahrenen Fachleute stehen Ihnen zur Verfügung, um Ihnen zu helfen, Ihr Zuhause in Topform zu halten. Kontaktieren Sie uns noch heute, um eine kostenlose Beratung zu erhalten.</p>
                
                <Link to='about' smooth={true} duration={500} spy={true} exact='true' offset={-100}>
                <button>Jetzt anfragen</button>
                </Link>
            </div>
        </header>
    )
}

export default HeaderContent;