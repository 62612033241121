import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import leWebsites from '../images/leWebsites.png';
import '../extensions/fontAwesome/css/all.min.css';
import '../extensions/fontAwesome/webfonts/fa-regular-400.ttf';

const FooterContent = () => {
    return(
        <footer>
            <div className='footerContainer'>
            <div className="footerLinks">
                <Link to="/dataProtection" >Datenschutz</Link>
                <Link to="/impressum" >Impressum</Link>
            </div>
            <p className="copyright">
                &copy; 2023 BZ-Dienstleistungen. Alle Rechte vorbehalten.
            </p>
            <img src={leWebsites} alt="LE Websites" loading='lazy'/>
            </div>
        </footer>
    )
}

export default FooterContent;