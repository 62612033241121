import React, {useEffect} from 'react';
import '../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../extensions/fontAwesome/css/all.min.css';
import '../extensions/fontAwesome/webfonts/fa-regular-400.ttf';

import ServiceOne from '../images/pexels-jeshootscom-834892.jpg';
import ServiceTwo from '../images/pexels-max-rahubovskiy-6032203.jpg';
import ServiceThree from '../images/img5.jpg';
import ServiceFour from '../images/pexels-snapwire-113726.jpg';
import ServiceFive from '../images/PHOTO-2023-03-16-22-46-37.JPG';
import ServiceSix from '../images/pexels-tima-miroshnichenko-6474490.jpg';

const ServiceContent = () => {
  useEffect(() => {
    AOS.init();
  }, [])
    return(
        <section id="services" className="services">
          <h1>Unser Angebot</h1>
          <div className='serviceContainer'>

          <div className="card">
            <img src={ServiceOne} alt=""/>
            <div className="cardText">
              <i className="fa-solid fa-helmet-safety"/>
              <h2>Bauleistungen</h2>
              {/* <p>
                Soon you can invest in Sid Harman's new attractive property, set in
                the heart of Athens.
              </p> */}
              <ul>
                  <li>
                    Hausrenovierung
                  </li>
                  <li>
                    Kernsanierung
                  </li>
                  <li>
                    Abbrucharbeiten
                  </li>
                  <li>
                    Trockenbau
                  </li>
                  <li>
                    Bodenbelege
                  </li>
                  <li>
                    Fliesenlegen
                  </li>
                  <li>
                    Holzarbeiten
                  </li>
                  <li>
                    Mauerarbeiten
                  </li>
                  <li>
                    Wandversetzen
                  </li>
                  <li>
                    Dachdecker
                  </li>
                  <li>
                    Dachisolierung
                  </li>
                  <li>
                    Innenisolierung
                  </li>
                  <li>
                    Fassaden
                  </li>
                </ul>
              {/* <button>Show the property project</button> */}
            </div>
          </div>
          <div className="card">
            <img src={ServiceTwo} alt=""/>
            <div className="cardText">
            <i className="fa-solid fa-droplet"/>
              <h2>Sanitär- und Heizungsinstallationen</h2>
              <ul>
                  <li>
                    Heizungen
                  </li>
                  <li>
                    Wasserinstallation
                  </li>
                  <li>
                    Sanitär
                  </li>
                </ul>
              {/* <button>Show the property project</button> */}
            </div>
          </div>
          <div className="card">
            <img src={ServiceThree} alt=""/>
            <div className="cardText">
              <i className="fa-brands fa-pagelines"/>
              <h2>Landschaftsbau</h2>
              <ul>
                  <li>
                    Gartenarbeiten
                  </li>
                  <li>
                    Pflastersteine
                  </li>
                  <li>
                    Rollrasen
                  </li>
                  <li>
                    Hecken
                  </li>
                  <li>
                    Büsche
                  </li>
                  <li>
                    Gartenpflege
                  </li>
                </ul>
              {/* <button>Show the property project</button> */}
            </div>
          </div>
          <div className="card">
            <img src={ServiceFour} alt=""/>
            <div className="cardText">
              <i className="fa-solid fa-eye-low-vision"/>
              <h2>Zaun- und Sichtschutzbau</h2>
              <ul>
                  <li>
                    Zäune
                  </li>
                  <li>
                    Sichtschutzelemente
                  </li>
                </ul>
              {/* <button>Show the property project</button> */}
            </div>
          </div>
          <div className="card">
            <img src={ServiceFive} alt=""/>
            <div className="cardText">
              <i className="fas fa-home"/>
              <h2>Hof- und Freiflächengestaltung</h2>
              <ul>
                  <li>
                    Höfe
                  </li>
                  <li>
                    Terrassen
                  </li>
                  <li>
                    Wege
                  </li>
                  <li>
                    Einfahrten
                  </li>
                  <li>
                    Parkplätze
                  </li>
                </ul>
              {/* <button>Show the property project</button> */}
            </div>
          </div>
          <div className="card">
            <img src={ServiceSix} alt=""/>
            <div className="cardText">
            <i className="fa-solid fa-person-shelter"/>
              <h2>Isolierung und Dämmung</h2>
              <ul>
                  <li>
                    Dachisolierung
                  </li>
                  <li>
                    Innenisolierung
                  </li>
                  <li>
                    Fassadendämmung
                  </li>
                </ul>
              {/* <button>Show the property project</button> */}
            </div>
          </div>
        </div>
      </section>  
    )
}

export default ServiceContent;