import React, {useEffect} from 'react';
import '../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from '../images/header.jpg';
import img2 from '../images/img4.jpg';
import img3 from '../images/img8.jpg';
import img4 from '../images/PHOTO-2023-03-16-22-46-36 2.JPG';
import img5 from '../images/PHOTO-2023-03-16-22-46-36 4.JPG';
import img6 from '../images/PHOTO-2023-03-16-22-47-02 2.JPG';
import img7 from '../images/img30.JPG';
import img8 from '../images/img1.jpg';
import '../extensions/fontAwesome/css/all.min.css';
import '../extensions/fontAwesome/webfonts/fa-regular-400.ttf';

const ImgGalleryContent = () => {
  useEffect(() => {
    AOS.init();
  }, [])
    return(
        <section id="imageGallery" >
            <h1>Projektgalerie</h1>
            <div className="images">
                <img src={img1} className="image-gallery-item" alt="" loading="lazy" />
                <img src={img2} className="image-gallery-item" alt="" loading="lazy" />
                <img src={img3} className="image-gallery-item" alt="" loading="lazy" />
                <img src={img4} className="image-gallery-item" alt="" loading="lazy" />
                <img src={img5} className="image-gallery-item" alt="" loading="lazy" />
                <img src={img6} className="image-gallery-item" alt="" loading="lazy" />
                <img src={img7} className="image-gallery-item" alt="" loading="lazy" />
                <img src={img8} className="image-gallery-item" alt="" loading="lazy" />
            </div>
        </section>
    )
}

export default ImgGalleryContent;